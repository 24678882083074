import React, { useState, useEffect } from "react";
import { Layout, Menu, Image, Button, Drawer, Dropdown } from "antd";
import useViewPort from "../services/responsiveHelper";
import SidebarContent from "./SidebarContent";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../assets/logo-original-white.svg";
import { ReactComponent as MenuIcon } from "../assets/menuDrawer.svg";
import { ReactComponent as Logo } from "../assets/logo-original.svg";
import packageJson from "../../package.json";
import { ReactComponent as LogoutIcon } from "../assets/icon_logout.svg";
import { ReactComponent as ProfileIcon } from "../assets/icon_profile-blue.svg";
import { ReactComponent as DownArrow } from "../assets/icon_down_arrow.svg";
import ConfirmModal from "./ConfirmModal";
import { useNavigate } from "react-router-dom";

const { Header } = Layout;

const MainHeader = () => {
  const { width } = useViewPort();
  const breakpoint = 991;
  const currentLocation = useLocation();
  const [collapsed, setCollapse] = useState(false);
  const [selectedKey, setSelectedKey] = useState("1");
  const [modalVisible, setModalVisible] = useState(false);
  const [profile, setProfile] = useState({});

  const navigate = useNavigate();

  const showDrawer = () => {
    setCollapse(true);
  };
  const onClose = () => {
    setCollapse(false);
  };

  useEffect(() => {
    const isExamineticsAdmin = /\/examinetics-admin(?:\/\w+)?$/.test(currentLocation.pathname);
    const isExamineticsAdminForm = /\/examinetics-admin-form(?:\/\w+)?$/.test(currentLocation.pathname);

    if (isExamineticsAdmin || isExamineticsAdminForm) {
      setSelectedKey("2");
    } else {
      setSelectedKey("1");
    }
  }, [currentLocation]);

  const handleLogout = () => {
    setModalVisible(false);
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    setProfile({
      firstName: localStorage.getItem("firstName"),
      lastName: localStorage.getItem("lastName"),
    });
  }, []);

  const examineticsAdminForm =
    window.location.pathname !== "/examinetics-admin-form" ? (
      <>
        <Header className="mobile-header">
          <Button
            type="primary"
            icon={<MenuIcon />}
            onClick={showDrawer}
            style={{ marginTop: "10px" }}
          />
          <div className="title">
            <Image
              src={logo}
              width="9.7rem"
              height="3.125rem"
              preview={false}
            />
          </div>
        </Header>
        <Header>
          <Menu mode="horizontal" defaultSelectedKeys={["1"]}>
            <Menu.Item key="1">
              <NavLink
                to={{
                  pathname: "/client-proctor",
                  state: currentLocation.state,
                }}
              >
                Client Proctors
              </NavLink>
            </Menu.Item>

            <Menu.Item key="2">
              <NavLink
                to={{
                  pathname: "/examinetics-admin",
                  state: currentLocation.state,
                }}
              >
                Examinetics Admins
              </NavLink>
            </Menu.Item>
          </Menu>
        </Header>
        <Drawer
          placement="top"
          onClose={onClose}
          open={collapsed}
          className="mobile-sidebar"
          width="50%"
        >
          <SidebarContent />
        </Drawer>
      </>
    ) : null;

  return width > breakpoint ? (
    <div className="header">
      <Header className="top-nav">
        <div>
        <Logo  onClick={() => navigate("/client-proctor")} className="logo-main" />
        <Menu
          className="menu-cls"
          mode="horizontal"
          selectedKeys={[selectedKey]}
          disabledOverflow={true}
        >
          <Menu.Item
            key="1"
            className="menu-cls-item"
          >
            <NavLink
              to={{
                pathname: "/client-proctor",
                state: currentLocation.state,
              }}
            >
              Client Proctors
            </NavLink>
          </Menu.Item>

          <Menu.Item
            key="2"
            className="menu-cls-item"
          >
            <NavLink
              to={{
                pathname: "/examinetics-admin",
                state: currentLocation.state,
              }}
            >
              Examinetics Admins
            </NavLink>
          </Menu.Item>

        </Menu>

        </div>
        <div className="top-nav-items-right">
          <div className="app-version">Version {packageJson.version}</div>
          <Dropdown
            className="header-user-dropdown"
            menu={{
              items: [
                {
                  key: "logout",
                  label: "Logout",
                  icon: <LogoutIcon />,
                  onClick: () => setModalVisible(true),
                },
              ],
            }}
            trigger={["click"]}
          >
            <Button type="text" icon={<ProfileIcon />}>
              {profile.firstName} {" "} {profile.lastName}
              <DownArrow />
            </Button>
          </Dropdown>
        </div>
      </Header>
      {/* Logout confirmation modal  */}
      <ConfirmModal
        title="Logout User?"
        content="Please confirm if you wish to Logout."
        visible={modalVisible}
        onConfirm={handleLogout}
        onCancel={() => {
          setModalVisible(false);
        }}
      />
    </div>
  ) : (
    examineticsAdminForm
  );
};

export default MainHeader;
