import React from "react";
import { Layout, Button, Input, Divider, Switch } from "antd";
import { useNavigate } from "react-router-dom";
import { PropTypes } from "prop-types";
import { ReactComponent as BackIcon } from "../assets/icon_back.svg";
import CustomPageHeader from "./CustomPageHeader";
const { Header } = Layout;

const { Search } = Input;

const PageHead = (props) => {
  const {
    children,
    title,
    subTitle,
    backBtnPath,
    btnType,
    searchPlaceholderTxt,
    dropDown,
    btnAdd,
    onBtnClick,
    btnUpdate,
    toggleStatus,
    onChangeSwitch,
    defaultChecked,
    switchDisabled,
    divider,
    routeState,
    formName,
    onBtnEdit,
    toggleClassname,
    searchValue,
    width,
    breakpoint,
    handleSearchChange,
    customStyles
  } = props;

  const history = useNavigate();
  const setButtonType = btnType ? btnType : "primary";
  const setFormName = formName ? formName : null;
  const extraTablePageHeaderContent =
    width > breakpoint
      ? [
          searchPlaceholderTxt ? (
            <Search
              // autoFocus
              placeholder={searchPlaceholderTxt}
              value={searchValue}
              onChange={(e) => handleSearchChange(e.target.value)}
              allowClear
              key="searchInput"
              className="search-bar"
            />
          ) : null,
          dropDown ? dropDown : null,
          toggleStatus ? (
            <span className="switch-status">
              <Switch
                onClick={onChangeSwitch}
                disabled={switchDisabled}
                checked={defaultChecked}
                key="switchStatus"
                className={
                  defaultChecked
                    ? switchDisabled
                      ? "switch-button-form-disabled"
                      : toggleClassname
                    : toggleClassname
                }
              />
              {toggleStatus}
            </span>
          ) : null,
          btnAdd ? (
            <Button
              type={setButtonType}
              onClick={onBtnClick}
              key="actionBtn1"
              shape="round"
              form={setFormName}
            >
              {btnAdd}
            </Button>
          ) : null,
          btnUpdate ? (
            <Button
              type="secondary"
              htmlType="button"
              onClick={onBtnEdit}
              key="clearActionBtn"
              shape="round"
              className="delete-btn-none"
              style={{
                marginRight: "0px",
              }}
              form={setFormName}
            >
              {btnUpdate}
            </Button>
          ) : null,
        ]
      : [
          toggleStatus ? (
            <span className="switch-status">
              <Switch
                onClick={onChangeSwitch}
                disabled={switchDisabled}
                checked={defaultChecked}
                key="switchStatus"
                className={
                  defaultChecked
                    ? switchDisabled
                      ? "switch-button-form-disabled"
                      : toggleClassname
                    : toggleClassname
                }
              />
              {toggleStatus}
            </span>
          ) : null,
          btnAdd ? (
            <Button
              type={setButtonType}
              onClick={onBtnClick}
              key="actionBtn"
              shape="round"
              form={setFormName}
            >
              {btnAdd}
            </Button>
          ) : null,
          searchPlaceholderTxt ? (
            <Search
              // autoFocus
              placeholder={searchPlaceholderTxt}
              value={searchValue}
              onChange={(e) => handleSearchChange(e.target.value)}
              allowClear
              key="searchInput"
              className="search-bar"
            />
          ) : null,
          dropDown ? dropDown : null,
          btnUpdate ? (
            <Button
              type="secondary"
              htmlType="button"
              onClick={onBtnEdit}
              key="clearActionBtn"
              shape="round"
              className="delete-btn-none"
              style={{
                marginRight: "0px",
              }}
              form={setFormName}
            >
              {btnUpdate}
            </Button>
          ) : null,
        ];

  /** Useful When Data View */
  return (
    <CustomPageHeader
      subTitle={subTitle}
      title={title}
      backIcon={<BackIcon height="30px" />}
      onBack={
        backBtnPath
          ? () => {
              history(backBtnPath, routeState ? routeState : {}); //TODO
            }
          : false
      }
      extra={extraTablePageHeaderContent}
      className={width > breakpoint ? "page-header" : "page-header responsive"}
      customStyles={customStyles}
    >
      {divider && <Divider style={{ margin: "0" }} />}
      {children}
    </CustomPageHeader>
  );
};

PageHead.propTypes = {
  backBtnPath: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

export default PageHead;
