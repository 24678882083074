import React, { useState, useEffect } from "react";
import {
  Select,
  Spin,
  Skeleton,
  Divider,
  Typography,
  Space,
  Table,
  Input,
  AutoComplete,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import PageHead from "../../components/PageHead";
import apiCall from "../../services/apiCall";
import ToastNotification from "../../helpers/ToastNotification";
import useViewPort from "../../services/responsiveHelper";
import ListClients from "../../components/ListClients";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useLocation } from "react-router-dom";
import { SourceConstants, UserStatusConstants } from "../../helpers/Constants";
import { useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { ReactComponent as EmailIcon } from "../../assets/icon_mail.svg";
import PopOver from "../../components/PopOver";

const { Text } = Typography;

// helper to avoid local storage key collisions
const lSKeyPrefix = (value) => {
  if (value === "") {
    return value;
  }
  return `clientProctors-${value}`;
};

const customPageSize = 10;
const workOrderNoFilterLSKey = lSKeyPrefix("workOrderNo");
const proctorNameFilterLSKey = lSKeyPrefix("proctorName");
const emailFilterLSKey = lSKeyPrefix("email");
const tableCurrentPageLSKey = lSKeyPrefix("tableCurrentPage");
const sourceFilterLSKey = lSKeyPrefix("source");
const statusFilterLSKey = lSKeyPrefix("status");

const isSourceValid = (source) => {
  return new Set(Object.values(SourceConstants)).has(source);
};

const isStatusValid = (status) => {
  return new Set(["Active", "InActive", "Pending"]).has(status);
};

const ClientProctors = () => {
  const { width } = useViewPort();
  const breakpoint = 670;
  // VW - viewport width
  const isVWLessThanBreakpoint = width < breakpoint;
  const history = useNavigate();
  const location = useLocation();

  const [clientList, setClientList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [currentPage, setCurrentPage] = useState(() => {
    if (isVWLessThanBreakpoint) {
      return 1;
    }
    const currentPageInLS = localStorage.getItem(tableCurrentPageLSKey);
    const currentPageConverted = Number(currentPageInLS);
    if (Number.isFinite(currentPageConverted)) {
      return Math.max(1, currentPageConverted);
    }
    return 1;
  });

  const { source, status, workOrderNo, email, proctorName } =
    location.state || {};

  const [filters, setFilters] = useState(() => {
    const res = {
      workOrderNo,
      email,
      proctorName,
      source,
      status,
    };
    if (!isVWLessThanBreakpoint) {
      res.workOrderNo ??=
        localStorage.getItem(workOrderNoFilterLSKey) || undefined;
      res.email ??= localStorage.getItem(emailFilterLSKey) || undefined;
      res.proctorName ??=
        localStorage.getItem(proctorNameFilterLSKey) || undefined;
      if (!res.status) {
        const statusFromLS = localStorage.getItem(statusFilterLSKey);
        if (isStatusValid(statusFromLS)) {
          res.status = statusFromLS;
        }
      }
      if (!res.source) {
        const sourceFromLS = localStorage.getItem(sourceFilterLSKey);
        if (sourceFromLS === null) {
          // first-time visit
          res.source = SourceConstants.SALUX_VAULT;
        } else if (isSourceValid(sourceFromLS)) {
          res.source = sourceFromLS;
        } else {
          res.source = "";
        }
      }
    }
    return res;
  });

  const organisations = useSelector((state) => state.organisations.data);
  const facilities = useSelector((state) => state.facilities.data);

  const populateProctors = React.useCallback(
    async (page, size, filtersLocal) => {
      setLoadingList(true);
      const payload = {
        workOrder: filtersLocal.workOrderNo,
        proctorName: filtersLocal.proctorName,
        email: filtersLocal.email,
        facilityId: filtersLocal.facilityId,
        clientId: filtersLocal.clientId,
        source: filtersLocal.source,
        status: filtersLocal.status,
      };
      if (!payload.source) {
        delete payload.source;
      }
      if (!payload.status) {
        delete payload.status;
      }
      const data = await apiCall.getClientProctors(page - 1, size, payload);
      if (data.status === 200) {
        const fetchedClientList = data?.data?.docs;
        setClientList(fetchedClientList);
        setTotalCount(data?.data?.totalDocs);
        const pageFromApi = data?.data?.page;
        // serves two purposes
        // 1. the `Table` component won't display pagination ui
        // 2. breaks the infinite fetch cycle as the `currentPage` value is a dependency of the data fetch
        if (pageFromApi === undefined || fetchedClientList.length === 0) {
          setCurrentPage(undefined);
        }
      } else {
        ToastNotification("error", "Error fetching User records.");
      }
      setLoadingList(false);
    },
    []
  );

  React.useEffect(() => {
    if (typeof currentPage !== "number") {
      return;
    }
    populateProctors(currentPage, customPageSize, filters);
  }, [filters, currentPage, populateProctors]);

  React.useEffect(() => {
    if (typeof currentPage !== "number") {
      localStorage.removeItem(tableCurrentPageLSKey);
      return;
    }
    localStorage.setItem(tableCurrentPageLSKey, currentPage);
  }, [currentPage]);

  React.useEffect(() => {
    if (filters.status === undefined) {
      localStorage.removeItem(statusFilterLSKey);
    } else {
      localStorage.setItem(statusFilterLSKey, filters.status);
    }
  }, [filters.status]);

  React.useEffect(() => {
    localStorage.setItem(
      sourceFilterLSKey,
      !filters.source ? "" : filters.source
    );
  }, [filters.source]);

  const onFilterChange = React.useCallback((newValue, changedKey) => {
    setFilters((curFilters) => {
      if (
        curFilters[changedKey] === newValue ||
        (curFilters[changedKey] === undefined && !newValue)
      ) {
        return curFilters;
      }
      const newFilters = { ...curFilters };
      if (newValue) {
        newFilters[changedKey] = newValue;
      } else {
        delete newFilters[changedKey];
      }
      return newFilters;
    });
    setCurrentPage(1);
  }, []);

  const columns = [
    {
      title: (
        <TableTitle
          titleText="Workorder"
          searchPlaceholder="Search"
          localStorageKey={workOrderNoFilterLSKey}
          onFilterChange={onFilterChange}
          {...filterKeyRelatedProps(filters, "workOrderNo")}
        />
      ),
      filteredValue: useFilteredValue(filters.workOrderNo),
      dataIndex: "workOrderNo",
      key: "workOrderNo",
      width: 150,
      render: (_text, record) => {
        const data = record?.workOrders || [];
        const remainingData = record?.workOrders?.slice(1) || [];

        if (data.length > 0) {
          const content = (
            <div>
              {remainingData.map((item, index) => (
                <div key={index}>
                  <span>{item.workOrderNo}</span>
                </div>
              ))}
            </div>
          );

          return (
            <Space>
              {data[0].workOrderNo}
              {remainingData.length > 0 && (
                <PopOver content={content}>
                  <Text
                    className="popover-workorder-text"
                    underline={true}
                  >{`+${remainingData.length}`}</Text>
                </PopOver>
              )}
            </Space>
          );
        }
      },
    },
    {
      title: (
        <TableTitle
          titleText="Proctor Name"
          searchPlaceholder="Search"
          localStorageKey={proctorNameFilterLSKey}
          onFilterChange={onFilterChange}
          {...filterKeyRelatedProps(filters, "proctorName")}
        />
      ),
      width: 210,
      filteredValue: useFilteredValue(filters.proctorName),
      dataIndex: ["firstName", "lastName"],
      key: "proctorName",
      filterMultiple: false,
      render: (_text, record) => (
        <span>
          {record.firstName} {record.lastName}
        </span>
      ),
    },
    {
      title: (
        <TableTitle
          titleText="Email"
          searchPlaceholder="Search"
          localStorageKey={emailFilterLSKey}
          onFilterChange={onFilterChange}
          {...filterKeyRelatedProps(filters, "email")}
        />
      ),
      width: 270,
      filteredValue: useFilteredValue(filters.email),
      dataIndex: "email",
      key: "email",
      render: (email) => (
        <span className="table-cell-container">
          <div className="table-cell-icon">
            <EmailIcon />
          </div>
          {email}
        </span>
      ),
    },
    {
      // this won't work at the moment as we will need to setup `Select`
      title: (
        <TableTitle
          titleText="Client Name"
          searchPlaceholder="Search"
          localStorageKey="clientName"
          onFilterChange={onFilterChange}
          {...filterKeyRelatedProps(filters, "clientName")}
          allowSearch={false}
          autocomplete={true}
          data={organisations}
        />
      ),
      filteredValue: useFilteredValue(filters.clientName),
      dataIndex: "clientName",
      key: "clientName",
    },
    {
      // this won't work at the moment as we will need to setup `Select`
      title: (
        <TableTitle
          titleText="Facility Name"
          searchPlaceholder="Search"
          localStorageKey="facilityName"
          onFilterChange={onFilterChange}
          {...filterKeyRelatedProps(filters, "facilityName")}
          allowSearch={false}
          autocomplete={true}
          data={facilities}
        />
      ),
      filteredValue: useFilteredValue(filters.facilityName),
      dataIndex: "facilityName",
      key: "facilityName",
      render: (_text, record) => {
        const data = record?.facilityNames || [];
        const remainingData = record?.facilityNames?.slice(1) || [];

        if (data.length > 0) {
          const content = (
            <div>
              {remainingData.map((item, index) => (
                <div key={index}>
                  <span>{item}</span>
                </div>
              ))}
            </div>
          );

          return (
            <div className="facility-names-wrapper">
              <div className="facility-name">{data[0]}</div>
              <div className="remaining-facility-count">
                {remainingData.length > 0 && (
                  <PopOver content={content}>
                    <Text
                      className="popover-workorder-text"
                      underline={true}
                    >{`+${remainingData.length}`}</Text>
                  </PopOver>
                )}
              </div>
            </div>
          );
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 110,
      render: (status) => {
        let color;
        if (status === "Active") color = "rgb( 98, 181, 229)";
        else if (status === "InActive") color = "rgb( 154,154,154)";
        else color = "rgb( 232,119,34)";

        return (
          <span>
            <span className="status" style={{ backgroundColor: color }} />
            <span className="status-text">{status ? status : "Pending"}</span>
          </span>
        );
      },
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 36,
        color: "rgb(0, 48, 135)",
        fontWeight: "bold",
      }}
      spin
    />
  );

  const pageHeaderProps = {
    btnAdd: "Add",
    onBtnClick: () => {
      history("/client-proctor-form", { state: {...location.state, isAddClient: true}});
    },
  };

  useEffect(() => () => {
    if (history.action === "POP") {
      history(1);
    }
  });

  const dropdownFilters = (
    <span className="select">
      <Space>
        <Select
          defaultValue={SourceConstants.SALUX_VAULT}
          value={filters.source}
          onSelect={(value) => {
            setFilters((prev) => ({ ...prev, source: value }));
            setCurrentPage(1);
            history("/client-proctor", {
              state: { ...location.state, source: value },
              replace: true,
            });
          }}
          className="user-type-dropdown-source"
        >
          <Select.Option value="">All Users</Select.Option>
          <Select.Option value={SourceConstants.SALUX_VAULT}>
            Salux Vault
          </Select.Option>
          <Select.Option value={SourceConstants.WRE}>WRE</Select.Option>
        </Select>
        <Select
          defaultValue=""
          value={filters.status}
          onSelect={(value) => {
            setFilters((prev) => ({ ...prev, status: value }));
            setCurrentPage(1);
            history("/client-proctor", {
              state: { ...location.state, status: value },
              replace: true,
            });
          }}
          className="user-type-dropdown-status"
        >
          <Select.Option value={UserStatusConstants.ALL_USERS}>
            All Users
          </Select.Option>
          <Select.Option value={UserStatusConstants.ACTIVE}>
            Active Users
          </Select.Option>
          <Select.Option value={UserStatusConstants.INACTIVE}>
            InActive Users
          </Select.Option>
          <Select.Option value={UserStatusConstants.PENDING}>
            Pending Users
          </Select.Option>
        </Select>
      </Space>
    </span>
  );

  const showList = isVWLessThanBreakpoint ? (
    <div id="scrollableDiv" className="infinite-scroll-container">
      <InfiniteScroll
        dataLength={totalCount}
        next={() => {
          if (loadingList) {
            return;
          }
          setCurrentPage((currentPage) => currentPage + 1);
        }}
        hasMore={totalCount < 100}
        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        endMessage={<Divider plain>It is all, nothing more </Divider>}
        scrollableTarget="scrollableDiv"
        className="infinite-scroll"
      >
        <ListClients data={clientList} />
      </InfiniteScroll>
    </div>
  ) : (
    <div className="table-container">
      <Table
        columns={columns}
        dataSource={clientList}
        rowKey="id"
        loading={loadingList}
        pagination={{
          total: totalCount,
          showSizeChanger: false,
          pageSize: customPageSize,
          current: currentPage,
        }}
        onChange={({ current }) => {
          setCurrentPage(current);
        }}
        rowClassName={(_record, index) =>
          index % 2 === 0
            ? "custom-row eCloud-table-row-light"
            : "custom-row eCloud-table-row-dark"
        }
        onRow={(recordIndex) => ({
          onClick: () =>
            history(`/client-proctor-form/${recordIndex._id}`, {
              state: { ...location.state, id: recordIndex._id },
            }),
        })}
        className="eCloud-table"
      />
    </div>
  );

  const organisationsStatus = useSelector((state) => {
    return state.organisations.status;
  });

  const facilitiesStatus = useSelector((state) => state.facilities.status);

  // Handle loading or error states
  if (organisationsStatus === "loading" || facilitiesStatus === "loading") {
    return <div>Loading...</div>;
  }

  //|| facilitiesStatus === "failed"
  if (organisationsStatus === "failed") {
    return <div>Error loading data.</div>;
  }

  return (
    <div className="main-container">
      <PageHead
        title={"Client Proctors"}
        dropDown={dropdownFilters}
        {...pageHeaderProps}
        width={width}
        breakpoint={breakpoint}
        customStyles
      >
        {pageLoading ? (
          <Spin indicator={antIcon} className="loader" />
        ) : (
          showList
        )}
      </PageHead>
    </div>
  );
};

export default ClientProctors;

function TableTitle({
  searchDefaultValue,
  titleText,
  localStorageKey,
  searchPlaceholder,
  filterKey,
  onFilterChange,
  autocomplete,
  data,
  allowSearch = true,
}) {
  const [searchText, setSearchText] = React.useState(searchDefaultValue ?? "");

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, searchText);
  }, [localStorageKey, searchText]);

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {titleText}
      {allowSearch ? (
        autocomplete ? (
          <AutoComplete
            placeholder={searchPlaceholder}
            options={data}
            suffixIcon={<SearchOutlined />}
            style={{ width: "100%" }}
            allowClear={true}
            filterOption={(inputValue, option) =>
              option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
            popupMatchSelectWidth={true}
            onClear={() => {
              onFilterChange("", filterKey);
            }}
          />
        ) : (
          <Input
            placeholder={searchPlaceholder}
            value={searchText}
            onChange={(e) => {
              const { value } = e.target;
              setSearchText(value);
            }}
            onPressEnter={() => {
              onFilterChange(searchText, filterKey);
            }}
            onClear={() => {
              onFilterChange("", filterKey);
            }}
            suffix={<SearchOutlined />}
            allowClear={true}
          />
        )
      ) : (
        <></>
      )}
    </Space>
  );
}

function useFilteredValue(value) {
  return React.useMemo(() => (value ? [value] : null), [value]);
}

// a helper to avoid repeating the `filterKey` prop
function filterKeyRelatedProps(filters, filterKey) {
  return {
    searchDefaultValue: filters[filterKey],
    filterKey,
  };
}
