import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiCall from "../../services/apiCall";
import httpService from "../../services/httpService";

// Async thunk for fetching facilities
export const fetchFacilities = createAsyncThunk(
  "organisations/fetchFacilities",
  async (_, { rejectWithValue }) => {
    try {
      const response = await httpService.get("/facilities");

      return response.data.map((item) => ({
        label: item.facilityName,
        value: item.facilityId,
      }));
    } catch (error) {
      // Pass the error message to Redux state
      console.error("Facilities API call failed");
      return rejectWithValue(error.response?.data || "API call failed");
    }
  }
);

const facilitiesSlice = createSlice({
  name: "facilities",
  initialState: {
    data: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFacilities.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFacilities.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchFacilities.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default facilitiesSlice.reducer;
