import { Navigate, Outlet } from "react-router-dom";
import { RouteConstants } from "../helpers/Constants";
import HomePage from "../pages/Dashboard/HomePage";

const ProtectedLayout = () => {
  const isAuthenticated = !!localStorage.getItem("token");
  return isAuthenticated ? (
    <HomePage>
      <Outlet />
    </HomePage>
  ) : (
    <Navigate to={RouteConstants.Others.Login.path} />
  );
};

export default ProtectedLayout;
