import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { RouteConstants } from "../helpers/Constants";
// import RequireAuth from "./RequireAuth";
import ProtectedLayout from "./ProtectedRouteLayout";

const AuthRoutes = () => (
  <Router>
    <Routes>
      {/* Default route with conditional redirect */}
      <Route
        path="/"
        element={
          localStorage.getItem("token") ? (
            <Navigate to={RouteConstants.HomePage.ClientProctors.path} />
          ) : (
            <Navigate to={RouteConstants.Others.Login.path} />
          )
        }
      />
      {/* unauthenticated page routes */}
      {Object.entries(RouteConstants.Others).map(([key, route]) => (
        <Route key={route.path} path={route.path} element={route.component} />
      ))}
      {/* Protected routes wrapped in HomePage layout */}
      <Route element={<ProtectedLayout />}>
        {Object.entries(RouteConstants.HomePage).map(([key, route]) => (
          <Route key={route.path} path={route.path} element={route.component} />
        ))}
      </Route>
      {/* Fallback routes */}
      <Route
        path="*"
        element={
          localStorage.getItem("token") ? (
            <Navigate
              to={RouteConstants.HomePage.ClientProctors.path}
              replace
            />
          ) : (
            <Navigate to={RouteConstants.Others.Login.path} replace />
          )
        }
      />
    </Routes>
  </Router>
);
export default AuthRoutes;