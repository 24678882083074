import React from "react";
import "../../styles/login.css";
import apiCall from "../../services/apiCall";
import { ReactComponent as Logo } from "../../assets/logo-original.svg";
import { ReactComponent as CheckIcon } from "../../assets/icon_check.svg";
import { ReactComponent as EyeOn } from "../../assets/icon_eye_on.svg";
import { ReactComponent as EyeOff } from "../../assets/icon_eye_off.svg";
import ToastNotification from "../../helpers/ToastNotification";
import { Form, Input, Button, Card, Typography, Row, Col } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import Copyright from "../../components/Copyright";

const Login = () => {
    const location = useLocation();
    const fromResetPassword = location.state
        ? location.state.fromResetPassword
        : "";

    const [form] = Form.useForm();

    const navigate = useNavigate();

    const onFinish = async (values) => {
        const response = await apiCall.login(values.email, values.password);
        if (response.status === 200) {
            ToastNotification("success", "Logged in successfully.");
            navigate(`/client-proctor`);
        } else {
            form.setFields([
                {
                    name: "password",
                    errors: [
                        response.data ? response.data : "Something went wrong"
                    ]
                }
            ]);
        }
    };

    return (
        <div className="login-container">
            <Row align="middle" style={{ width: "100%" }}>
                <Col
                    xs={{ span: 20, offset: 2 }}
                    sm={{ span: 18, offset: 3 }}
                    md={{ span: 16, offset: 4 }}
                    lg={{ span: 14, offset: 5 }}
                    xl={{ span: 10, offset: 7 }}
                    className="box-modal"
                >
                    <Row justify="center" align="top">
                        <div className="logo-container">
                            <Logo />
                        </div>
                    </Row>

                    <Card className="login-box">
                        <Form
                            form={form}
                            requiredMark={false}
                            name="normal_login"
                            layout="vertical"
                            className="login-form"
                            initialValues={{
                                remember: true
                            }}
                            onFinish={onFinish}
                        >
                            {fromResetPassword ? (
                                <div className="login-reset-container">
                                    <CheckIcon />
                                    <Typography className="login-reset-text">
                                        Password has been reset.
                                    </Typography>
                                </div>
                            ) : (
                                ""
                            )}

                            <Typography className="login-title">
                                {" "}
                                Login to Salux Vault{" "}
                            </Typography>
                            <Typography className="login-description">
                                Enter email and password to login.
                            </Typography>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                        message: "Please enter valid Email."
                                    }
                                ]}
                            >
                                <Input
                                    placeholder="Ex: John@gmail.com"
                                    autoFocus
                                />
                            </Form.Item>
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter your Password."
                                    },
                                    {
                                        min: 8,
                                        max: 25,
                                        message:
                                            "Password must be 8-25 characters long"
                                    }
                                ]}
                            >
                                <span
                                    className="password"
                                    style={{ height: "35.14px" }}
                                >
                                    <Input.Password
                                        placeholder="Password"
                                        iconRender={(visible) =>
                                            visible ? <EyeOn /> : <EyeOff />
                                        }
                                    />
                                </span>
                            </Form.Item>

                            <Form.Item>
                                <div className="login-button-container">
                                    <Button
                                        type="link"
                                        size="small"
                                        onClick={() => {
                                            navigate("/forgot-password") 
                                        }}
                                    >
                                        Forgot Password?
                                    </Button>
                                    <Button
                                        type="primary"
                                        shape="round"
                                        htmlType="submit"
                                    >
                                        Login
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>

                    <Row justify="center" align="bottom">
                        <Copyright />
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
export default Login;
