import { configureStore } from "@reduxjs/toolkit";
import organisationsReducer from "./features/organisationsSlice";
import facilitiesReducer from "./features/facilitiesSlice";

const store = configureStore({
  reducer: {
    organisations: organisationsReducer,
    facilities: facilitiesReducer,
  },
});

export default store;
