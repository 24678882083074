import React from "react";
import { Table } from "antd";

const ContentTable = (props) => {
    const { data, columns, pagination, onClick } = props;
    return (
        <div className="table-container">
            <Table
                onRow={onClick}
                dataSource={data}
                columns={columns}
                rowKey={(record) => record._id}
                pagination={{
                    defaultPageSize: 10,
                    hideOnSinglePage: true,
                    showSizeChanger: false,
                    responsive: true,
                    ...pagination
                }}
                rowClassName={(_record, index) =>
                    index % 2 === 0
                        ? "custom-row eCloud-table-row-light"
                        : "custom-row eCloud-table-row-dark"
                }
                className="eCloud-table"
            />
        </div>
    );
};

export default ContentTable;
